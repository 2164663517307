/* Global Styles */
body {
  margin: 0;
  font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f5f5;
  color: #333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* App Container */
.App {
  text-align: center;
  max-width: 95%; /* Réduit l'espace à gauche et à droite */
  margin: 0 auto;
  padding: 20px;
}

/* Header Styles */
.App-header {
  background: linear-gradient(90deg, #007BFF 0%, #00C6FF 100%);
  padding: 30px;
  border-radius: 12px;
  color: white;
  margin-bottom: 40px;
  animation: fadeInDown 1s ease;
}

h1 {
  font-size: 3rem;
  margin: 0;
  font-weight: 700;
}

/* Main Content Styles */
main {
  background-color: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  animation: fadeInUp 1s ease;
}

/* Input Styles */
input {
  width: 100%;
  padding: 15px;
  margin: 20px 0;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

input:focus {
  border-color: #007BFF;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
}

/* Button Styles */
button {
  background-color: #007BFF;
  color: white;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Plans Container */
.plans-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  gap: 15px; /* Espacement entre les plans */
  flex-wrap: nowrap; /* Garde les plans sur une seule ligne */
}

/* Plan Card Styles */
.plan-card {
  background-color: #ffffff;
  border: 2px solid transparent;
  border-radius: 12px;
  padding: 20px;
  flex: 1; /* Les cartes prennent un espace égal */
  min-width: 280px;
  max-width: 32%; /* Assure que les cartes restent proportionnelles */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease; /* Réduction de la durée de l'animation */
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Assure une répartition égale du contenu */
  transform: perspective(1px) translateZ(0); /* Prépare l'élément pour l'effet de survol */
  backface-visibility: hidden; /* Masque les effets de l'animation 3D */
  will-change: transform; /* Optimise l'animation */
  z-index: 0; /* Assure que les éléments non sélectionnés restent en arrière-plan */
}

.plan-card:hover {
  border-color: #007BFF;
  transform: scale(1.03) rotate(0.5deg); /* Réduction de l'agrandissement et de l'inclinaison */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Réduction de l'ombre */
  z-index: 1; /* Met la carte en survol au-dessus des autres */
}

.plan-card.selected {
  border-color: #007BFF;
  background-color: #e9f1ff;
  z-index: 2; /* Assure que la carte sélectionnée est toujours au-dessus des autres */
}

/* Plan Title Styles */
.plan-card h2, .selected-plan-details h2 {
  font-weight: 800; /* Gras */
  color: #0056b3; /* Couleur accentuée */
  font-size: 2rem; /* Plus grand */
  margin-bottom: 20px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); /* Ombre pour mise en avant */
  text-decoration: underline;
  transition: color 0.3s ease, transform 0.3s ease;
}

/* Section Titles (I, II, III...) - Different Colors */
.plan-card h3,
.selected-plan-details h3 {
  font-weight: 700;
  margin-bottom: 10px;
  transition: color 0.3s ease, transform 0.3s ease;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Légère ombre pour différenciation */
  text-align: justify; /* Justification des titres de sections */
}

.plan-card h3:nth-of-type(1),
.selected-plan-details h3:nth-of-type(1) { color: #FF5733; } /* Rouge-orangé pour la première section */
.plan-card h3:nth-of-type(2),
.selected-plan-details h3:nth-of-type(2) { color: #33A1FF; } /* Bleu clair pour la deuxième section */
.plan-card h3:nth-of-type(3),
.selected-plan-details h3:nth-of-type(3) { color: #28A745; } /* Vert pour la troisième section */
.plan-card h3:nth-of-type(4),
.selected-plan-details h3:nth-of-type(4) { color: #FFC107; } /* Jaune pour la quatrième section */

/* Sous-Parties (A, B, C, etc.) - Matching Colors */
.plan-card ul li,
.selected-plan-details ul li {
  margin-left: 20px;
  text-align: justify; /* Justification des sous-parties */
  color: inherit; /* Hérite de la couleur du parent (h3) */
}

.plan-card h4,
.selected-plan-details h4 {
  text-align: justify; /* Justification des H4 */
  color: inherit; /* Hérite de la couleur du parent */
}

.plan-card:hover h2 {
  color: #0040a1;
  transform: scale(1.05);
}

.plan-card:hover h3,
.plan-card:hover ul li,
.plan-card:hover h4 {
  transform: scale(1.03);
}

/* Modifier le Plan Title Styles */
.modify-plan-title {
  background: linear-gradient(90deg, #007BFF 0%, #00C6FF 100%);
  padding: 30px;
  border-radius: 12px;
  color: white;
  margin-top: 40px;
  animation: fadeInDown 1s ease;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  font-size: 2.5rem;
  font-weight: 800;
}

/* Editable Plan Content */
.editable-plan {
  margin-top: 20px;
  padding: 20px;
  border-radius: 12px;
  background-color: #f8f9fa;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease;
  text-align: justify;
  color: #333;
  font-size: 1rem;
  line-height: 1.6;
}

.editable-plan h2 {
  font-size: 2rem;
  color: #0056b3;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  text-decoration: underline;
}

.editable-plan h3 {
  font-weight: 700;
  margin-bottom: 10px;
  text-align: justify;
}

.editable-plan h3:nth-of-type(1) { color: #FF5733; }
.editable-plan h3:nth-of-type(2) { color: #33A1FF; }
.editable-plan h3:nth-of-type(3) { color: #28A745; }
.editable-plan h3:nth-of-type(4) { color: #FFC107; }

.editable-plan ul li {
  margin-left: 20px;
  text-align: justify;
  color: inherit;
}

.editable-plan h4 {
  text-align: justify;
  color: inherit;
}

/* Generated Dissertation Styles */
.generated-dissertation {
  margin-top: 40px;
  padding: 30px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease;
}

.generated-dissertation h2 {
  margin-top: 0;
  color: #007BFF;
}

.generated-dissertation p {
  color: #555;
  line-height: 1.6;
}

/* Keyframes for animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .App-header h1 {
    font-size: 2.5rem;
  }

  main {
    padding: 30px;
  }

  input {
    font-size: 0.9rem;
  }

  button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  .plans-container {
    flex-direction: column;
    align-items: center;
  }

  .plan-card {
    width: 100%;
    margin-bottom: 20px;
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .App-header h1 {
    font-size: 2rem;
  }

  main {
    padding: 20px;
  }

  button {
    padding: 8px 16px;
    font-size: 0.8rem;
  }
}

/* Generated Dissertation Title Styles */
.generated-dissertation-title {
  background: linear-gradient(90deg, #007BFF 0%, #00C6FF 100%);
  padding: 30px;
  border-radius: 12px;
  color: white;
  margin-top: 40px;
  animation: fadeInDown 1s ease;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  font-size: 2.5rem;
  font-weight: 800;
  text-decoration: none; /* Assure que le texte ne soit pas souligné */
}

/* Generated Dissertation Container */
.generated-dissertation-container {
  background-color: #f8f9fa;
  padding: 30px;
  border-radius: 12px;
  margin-top: 40px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.generated-dissertation {
  text-align: justify;
  color: #333;
  font-size: 1rem;
  line-height: 1.6;
}
